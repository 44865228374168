<div *ngIf="logoConfig && logoConfig.logo === 'alpha' && logoConfig.type === 'main'" class="logo" fxLayoutAlign="center center">
    <img id="alphaLogo" src="../../../../assets/imgs/logo-mobilify-stacked.svg" alt="Mobilify">
</div>
<div *ngIf="logoConfig && logoConfig.logo === 'alpha' && logoConfig.type === 'alt'" class="logo" fxLayoutAlign="center center">
    <img id="alphaLogo" src="../../../../assets/imgs/Mobilify-Logo-Horizontal-color.svg" alt="Mobilify">
</div>
<div *ngIf="logoConfig && logoConfig.logo === 'benefitsBuilder'" class="logo" fxLayoutAlign="center center">
    <img id="bbLogo" src="../../../../assets/imgs/Benefits Builder logo.svg" alt="Benefits Builder">
</div>
<div *ngIf="logoConfig && logoConfig.logo === 'movePro' && logoConfig.type === 'main'" class="logo" fxLayoutAlign="center center">
    <img id="moveProLogo" src="../../../../assets/imgs/MovePro360-Color.svg" alt="MovePro360">
</div>
<div *ngIf="logoConfig && logoConfig.logo === 'movePro' && logoConfig.type === 'alt'" class="logo movePro header-logo-movepro" fxLayoutAlign="center center">
    <img id="moveProLogo" src="../../../../assets/imgs/MovePro360-White.svg" alt="MovePro360">
</div>
<div *ngIf="logoConfig && (logoConfig.logo === 'supplier')" class="cartus-logo logo" fxLayoutAlign="center center">
    <img id="supplierLogo" class="supplierImg" src="../../../../assets/imgs/logo-cartus.svg" alt="Supplier Portal">
</div>
<div *ngIf="logoConfig && (logoConfig.logo === 'cartus')" class="logo" fxLayoutAlign="center center">
    <img id="moveProLogo" class="supplierImg" src="../../../../assets/imgs/logo-cartus.svg" alt="Supplier Portal">
</div>
