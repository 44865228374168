import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelfRegistrationValidationService } from 'src/app/core/services/self-registration-validation.service';
import { User } from '../../../../core/models/User';
import { UserValidation } from '../../../../core/models/UserValidation';
import { PersistenceService, StorageType } from 'angular-persistence';
import { Subscription } from 'rxjs';
import { CustomSnackbarComponent } from '../../custom-snackbar/custom-snackbar.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfigService } from 'src/app/core/services/app-config.service';

/** Class for  SelfRegistrationValidateUser */
@Component({
  selector: 'app-self-registration-validate-user',
  templateUrl: './self-registration-validate-user.component.html',
  styleUrls: ['./self-registration-validate-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelfRegistrationValidationComponent implements OnInit {
  /** Input parameter userDetails received from parent component */
  @Input() userDetails: User;
  /** Input parameter step number received from parent component */
  @Input() step: number;
  /** Output parameter step number notified to parent component by emiting */
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  /** Subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** variable to store user validation details */
  userValidation: UserValidation = {} as UserValidation;
  /** variable for formgroup */
  regValidationForm: FormGroup;
  /** variable to to check OTP sent or not */
  isOtpSent = false;
  /** variable to have static content text */
  contentTextPrefix: string;
  /** variable to have static content text */
  contentTextPostfix: string;
  /** variable to store client contact name */
  clientContactName: string;
  /** variable to show static masked phone number text */
  phoneNumberHiddenChar: string;
  /** variable to store last 4 digits of phone number */
  PhoneNumberLastChars: string;
  /** variable to have static otp text */
  otpTextPrefix: string;
  /** variable to have static otp text */
  optTextPostfix: string;
  /** variable to check no of invalid OTP attemps */
  invalidOTPCount = 0;
  /** variable to enable Continue button */
  isContinueBtnEnable = false;
  /** variable to check client is candidate or transferee*/
  isCandidateorTransferee: boolean;
  /** string constants to be displayed in self registration validation page */
  templateString = {
    PHONE_PLACEHOLDER_TEXT: 'Phone number provided:',
    SEND_CODE_BTN: 'Send Code',
    RESEND_CODE: 'Resend the Code',
    CONTINUE_BTN: 'Continue'
  };
  /** error constants to be displayed in self registration page */
  errorString = {
    INVALID_OTP: 'Invalid security code, please try again.',
    OTP_ATTEMPTS_EXCEEDED: 'Invalid security code. You have exceeded five attempts, please request for a new code.'
  };

  /**
   * Base constructor
   * @param fb Formbuilder variable
   * @param persistenceService PersistenceService
   * @param spinner to get NgxSpinner
   * @param validationService SelfRegistrationValidationService
   * @param snackBar to get MatSnackBar
   */
  constructor(private readonly fb: FormBuilder,
    private readonly validationService: SelfRegistrationValidationService,
    private readonly persistenceService: PersistenceService,
    public snackBar: MatSnackBar,
    public appConfig: AppConfigService,
    public ngxSpinner: NgxSpinnerService) {
    this.contentTextPrefix = 'If you cannot receive text messages on this phone, please contact your representative at';
    this.contentTextPostfix = 'and provide them a suitable phone number that can.';
    this.clientContactName = 'Cartus';
    this.phoneNumberHiddenChar = '(***) *** - ';
    this.otpTextPrefix = 'You should receive a security code momentarily. If you do not, we can ';
    this.optTextPostfix = ' at your request. Once you receive a code, it will be valid for only 5 minutes before expiring.';
  }

  /** To Initialize Component */
  ngOnInit() {
    this.PhoneNumberLastChars = this.userDetails.phoneNumber.substr(this.userDetails.phoneNumber.length - 4);
    if (this.userDetails.roleName === 'candidate' || this.userDetails.roleName === 'transferee') {
      this.isCandidateorTransferee = true;
    } else {
      this.isCandidateorTransferee = false;
    }
    this.createControl();
  }

  /** To Create form Controls */
  createControl() {
    this.regValidationForm = this.fb.group({
      otp: ['', Validators.required]
    });
  }

  /** Store the current step in session */
  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit(): void {
    this.persistenceService.set('currentStep', JSON.stringify(this.step),
      { type: StorageType.SESSION });
  }

  /** method to send otp to the user */
  sendOTP() {
    if (!this.appConfig.getConfig('byPassOtp')) {
      const user: User = {} as User;
      user.phoneNumber = this.userDetails.phoneNumber;
      user.userId = this.userDetails.userId;
      this.subscription.add(
        this.validationService
          .sendOTP(user)
          .subscribe(response => {
            if (response === true) {
              this.isOtpSent = true;
            } else {
              this.snackBar.open(
                'We are unable to process your request at this time. Please try again later.',
                undefined,
                { duration: 5000 }
              );
            }
          })
      );
    } else {
      this.isOtpSent = true;
    }
  }

  /** Method to resend OTP to the User */
  resendOTP() {
    this.isContinueBtnEnable = false;
    this.regValidationForm.get('otp').setValue('');
    this.regValidationForm.get('otp').setErrors(null);
    this.regValidationForm.setErrors({ 'invalid': true });
    this.invalidOTPCount = 0;
    this.sendOTP();
    this.serveSnackBar();
  }

  /** Method to validate the enter OTP */
  validateOTP() {
    this.ngxSpinner.show();
    this.userValidation.otp = this.regValidationForm.get('otp').value;
    this.userValidation.userId = this.userDetails.userId;
    if (this.appConfig.getConfig('byPassOtp')) {
      this.ngxSpinner.hide();
      let currentStep;
      currentStep = parseInt(this.persistenceService.get('currentStep', StorageType.SESSION), 10);
      this.notify.emit(currentStep);
    } else {
      this.subscription.add(
        this.validationService
          .validateOTP(this.userValidation)
          .subscribe(response => {
            if (response === false) {
              this.ngxSpinner.hide();
              this.invalidOTPCount = this.invalidOTPCount + 1;
              this.isContinueBtnEnable = this.invalidOTPCount >= 5 ? false : true;
              if (this.invalidOTPCount < 5) {
                this.regValidationForm.controls['otp'].setErrors({
                  invalidOTP: true
                });
              } else {
                this.regValidationForm.controls['otp'].setErrors({
                  exceededAttempts: true
                });
              }
            } else {
              this.ngxSpinner.hide();
              let currentStep;
              currentStep = parseInt(this.persistenceService.get('currentStep', StorageType.SESSION), 10);
              this.notify.emit(currentStep);
            }
          })
      );
    }
  }

  /** Method to invalid otp count */
  checkOTP() {
    if (this.invalidOTPCount >= 5) {
      this.regValidationForm.setErrors({ 'invalid': false });
    }
  }

  /**
   * To set error message to fields
   * @param fieldName - Field Name
   */
  getErrorMessage(fieldName): string | undefined {
    if (fieldName === 'OTP') {
      if (this.regValidationForm.get('otp').hasError('required')) {
        this.isContinueBtnEnable = false;
      }
      return this.regValidationForm.get('otp').hasError('required')
        ? 'Please enter a Security Code'
        : '';
    }
  }

  /** To Display Snack Bar when OTP is resent. */
  serveSnackBar() {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: `Code Re-Sent to Mobile #`,
      duration: 5000
    });
  }
}
