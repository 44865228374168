/* eslint-disable camelcase */
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../../app/core/models/User';
import { UserRegister } from '../../app/core/models/UserRegister';
import { UserValidation } from '../../app/core/models/UserValidation';
const failedUpdate = 'Update of Credential failed';
const failedAuthn = 'Authentication failed';
const errorIdMock = 'oaeve3HEAfGS7-VNBmVtQ59bQ';
const validEmail = 'TEST_mohan.ashokan@mindtree.com';
const tokenExpiry = 1608665092;
const zoneTime = 'America/Los_Angeles';
const hrefUrl = 'https://cartuspoc.okta.com/api/v1/authn/cancel';
const accessToken = 'eyJraWQiOiJ4U21iVVhZOUJod2VXV1lLZktCW';
const idToken = 'eyJraWQiOiJ4U21iVVhZOUJod2VXV1lLZktCWWoyZU';
const oktaLink = "https://cartus.oktapreview.com/oauth2/auspye2nuzSGJ0Kje0h7";
const oktaScopes = ['openid', 'email', 'profile'];
const validationToken = {
  accessToken: {
    accessToken: accessToken,
    value: accessToken
    },
  idToken: {
    idToken: idToken,
    value: idToken
  }
};

const localUrl = 'http://localhost:4201/'

const oktaToken =  {
  code: '_xz74Ldw03iu3qEkLwks4bUrJyugUg7VdinqGrt2RDX',
  state: undefined,
  tokens: {
    accessToken: {
      accessToken: accessToken,
      authorizeUrl: `${oktaLink}/v1/authorize`,
      claims: {
        aud: 'cartusUsers',
        cid: "34jkop4uj09u4589035r",
        exp: tokenExpiry,
        iat: tokenExpiry,
        iss: oktaLink,
        jti: "ID.PHIOUEWYR09u78324_oiu90-3424u890843y76D3qdx",
        sAMAccountName: 'mohan.ashokan',
        scp: ['openid', 'email'],
        sub: "mohan.ashokan@mindtree.com",
        uid: "000uopy877438jndfsaW",
        ver: 1,
      },
      expiresAt: tokenExpiry,
      scopes: ['openid', 'email'],
      tokenType: 'Bearer',
      userinfoUrl: `${oktaLink}/v1/userinfo`,
      value: accessToken,
      },
    idToken: {
      authorizeUrl: `${oktaLink}/v1/authorize`,
      claims: {
        amr: ['pwd'],
        at_hash: "wLltFKL1oYihUOU-513s2Q",
        aud: "34jkop4uj09u4589035r",
        auth_time: tokenExpiry,
        email: "TEST_mohan.ashokan@mindtree.com",
        exp: tokenExpiry,
        iat: tokenExpiry,
        idp: '00wer9890fdsskl238s3',
        iss: oktaLink,
        jti: "ID.PHIOUEWYR09u78324_oiu90-3424u890843y76D3qdx",
        nonce: "qwjuewrklnjkoldscjDJNKD89080d9809890JDJDDD87902833KDWKL908Vldd0V",
        sub: "000uopy877438jndfsaW",
        ver: 1,
      },
      clientId: '0odj9873jd9sdf2334i6',
      expiresAt: tokenExpiry,
      idToken: idToken,
      issuer: oktaLink,
      scopes: ['openid', 'email'],
      value: idToken
    }
  }
};

const mockedwidgetResponse = {
  code: '_xz74Ldw03iu3qEkLwks4bUrJyugUg7VdinqGrt2RDX',
  state: 'IOlvrjRBnUDfWo2EQzslilHhZd4MBHkua7LDQCPAQ1DpQYm73PjmISgd6TT09NJY',
  status: 'SUCCESS',
  tokens: {
    accessToken: {
      accessToken: accessToken,
      value: accessToken,
      authorizeUrl: `${oktaLink}/v1/authorize`,
      expiresAt: tokenExpiry,
      scopes: oktaScopes,
      tokenType: 'Bearer',
      userinfoUrl: `${oktaLink}/v1/userinfo`,
      },
    idToken: {
      idToken: idToken,
      value: idToken,
      authorizeUrl: `${oktaLink}/v1/authorize`,
      claims: {
        amr: ['pwd'],
        at_hash: "wLltFKL1oYihUOU-513s2Q",
        aud: "34jkop4uj09u4589035r",
        auth_time: tokenExpiry,
        email: "TEST_mohan.ashokan@mindtree.com",
        exp: tokenExpiry,
        iat: tokenExpiry,
        idp: '00wer9890fdsskl238s3',
        iss: oktaLink,
        jti: "ID.PHIOUEWYR09u78324_oiu90-3424u890843y76D3qdx",
        name: "Mohan Ashokan",
        nonce: "qwjuewrklnjkoldscjDJNKD89080d9809890JDJDDD87902833KDWKL908Vldd0V",
        preferred_username: "mohan.ashokan@mindtree.com",
        sub: "000uopy877438jndfsaW",
        ver: 1,
      },
      clientId: "0odj9873jd9sdf2334i6",
      expiresAt: tokenExpiry,
      issuer: oktaLink,
      scopes: oktaScopes
    }
  }
};

const signOutError = {
  xhr: {
    status: 429
  }
};

const apiError = {
  status: 500
};

const logoMock = 'alpha';

const cookieArray = ['car-ses-tok',
                     'car-ses-time',
                     'car-token-claims',
                     'car-ses-username',
                     'car-token-expiresat',
                     'car-token-idtoken'];

const fullCookieArray = [...cookieArray, 'lastAction'];

export const CONFIG = {
    local: true,
    loggerURL: 'http://ec2-52-87-247-160.compute-1.amazonaws.com:8080',
    logapi: 'http://localhost:4400/',
    oktaClientId: '0oa1fxblvqAFpW88N357',
    oktaUrl: 'https://cartus.oktapreview.com/oauth2/auspye2nuzSGJ0Kje0h7',
    oktaRedirectUri: localUrl,
    oktaClientSecretId: 'UxuI3v1LBBMz4SVh5zqiAe7sNx98wnTYdpxhoAlp',
    environment: 'developer',
    api: {
        host: 'localhost',
        port: '4000',
        protocol: 'http',
        base_url: ''
    },
    costModel: {
        host: 'localhost',
        port: '4000',
        protocol: 'http',
        base_url: ''
    },
    resetPasswordEndpoint: '/user/clearAttempts',
    changePasswordEndpoint: '/user/changePassword',
    movepro360: 'https://movepro360dev01.cartus.com/',
    alpha: 'https://mobilifyhrdev01.cartus.com/',
    transferee: 'http://localhost:4204/',
    vendor: 'http://localhost:4205/',
    benefitsbuilder: 'http://localhost:4203/',
    supplier: 'http://localhost:4207/',
    Alpha_ProductName: 'mobilify',
    MovePro360_ProductName: 'MovePro360',
    BB_ProductName: 'Benefits Builder',
    validMockUser: { email: validEmail, password: loadValidCred() },
    mockUser: { username: validEmail, password: loadValidCred() },
    invalidMockUser: { email: 'Abc@mindtree.com', password: loadValidCred() },
    oktaToken: oktaToken,
    validationToken: validationToken,
    mockedwidgetResponse: mockedwidgetResponse,
    changePassword: {oldPassword: loadValidCred(),
    newPassword: loadValidCred() },
    validUserId: loadValidUserId(),
    validPassword: addUserCred(),
    validEmail: addUserEmail(),
    validCartusEmail: addCartusEmail(),
    getUserDetails: getUserDetails(),
    getMockClaim: getMockClaim(),
    getOktaSignOutError: signOutError,
    getApiError: apiError,
    logoMock: logoMock,
    cookieArray,
    fullCookieArray,
    mockedCookieService: {
      set() {},
      get() {},
      delete() {}
    },
    mockedCookieLogoSvc: {
      setCookies() {},
      setCookieforApp() {},
      setLogo(): string {
        return logoMock;
      },
      setLogoFromUser(): string {
        return logoMock;
      },
      removeCookies() {},
    },
    mockedOktaClient: {
      signInWithCredentials() {
        return new Promise(resolve => resolve( CONFIG.mockedAuthToken.transaction));
      },
      token: {
        async getWithoutPrompt(): Promise<any> {
          return new Promise(resolve => resolve( CONFIG.oktaToken ));
        },
        async getUserInfo() {
          return CONFIG.getUserDetails;
        }
      },
      async revokeAccessToken() {
      },
      async signOut() {
        return;
      },
      closeSession() {
        return Promise.resolve(true);
      },
      isAuthenticated() {
        return Promise.resolve(true);
      },
      tokenManager: {
        add(tokenType, token) {
          return [
            CONFIG.validationToken
          ];
        },
        setTokens() {}
      },
      async updateAuthState() {
        return Promise.resolve({
          isPending: false,
          isAuthenticated: true,
          accessToken: accessToken,
          idToken: idToken,
          error: ''
        });
      },
      authStateManager: {
        async updateAuthState() {
          return Promise.resolve({
            isPending: false,
            isAuthenticated: true,
            accessToken: accessToken,
            idToken: idToken,
            error: ''
          });
        },
      },
    },
    mockedOktaClientWithErrors: {
      signInWithCredentials() {
        return new Promise(resolve => resolve( CONFIG.mockedAuthToken.transaction));
      },
      token: {
        async getWithoutPrompt() {
          return new Promise<any>(resolve => resolve(CONFIG.oktaToken));
        },
        async getUserInfo() {
          return Promise.reject(CONFIG.getApiError);
        }
      },
      async revokeAccessToken() {
      },
      closeSession() {
        return Promise.reject(CONFIG.getOktaSignOutError);
      },
      tokenManager: {
        add(tokenType, token) {
          return [
            CONFIG.validationToken
          ];
        }
      }
    },
    mockedUnauthenticatedError: {
        errorCauses: [],
        errorCode: 'E0000004',
        errorId: errorIdMock,
        errorLink: 'E0000004',
        errorSummary: failedAuthn,
        message: failedAuthn,
        name: 'AuthApiError',
        xhr: {
          responseJSON: {
            errorCauses: [],
            errorCode: 'E0000004',
            errorId: errorIdMock,
            errorLink: 'E0000004',
            errorSummary: failedAuthn,
            message: failedAuthn,
          },
          responseText: {
              errorCauses: [],
              errorCode: 'E00000013',
              errorId: 'oaetJQB67yzTeeJpATL5bJj3A',
              errorLink: 'E00000013',
              errorSummary: failedAuthn
          },
          responseType: 'json',
          status: 401
      }
    },
    mockedAuthToken: {
        transaction: {
            expiresAt: tokenExpiry,
            sessionToken: '20111fRbpZC4kQ3mTB6Rs_U5LZgWJ3QX9NXDZ9kHs44xoujdDfoN8ao',
            status: 'SUCCESS',
            user: {
                id: '00u1f0rihj9lzNjoz357',
                passwordChanged: mockedAuthTokenPwdChanged()
            },
            profile: {
                firstName: 'mohan',
                lastName: 'ashokan',
                locale: 'en',
                login: validEmail,
                timeZone: zoneTime
            },
            _links: { cancel: { href: hrefUrl, hints: { allow: ['POST'] } } }
        },
        tokens: validationToken
    },
    mockedAuthTokenforWidget: {
      code: '',
      state: '',
      status: 'SUCCESS',
      tokens: validationToken
    },
    mockedAuthTokenLockedOut: {
        transaction: {
            expiresAt: tokenExpiry,
            sessionToken: '20111fRbpZC4kQ3mTB6Rs_U5LZgWJ3QX9NXDZ9kHs44xoujdDfoN8ao',
            status: 'LOCKED_OUT',
            user: {
                id: '00u1f0rihj9lzNjoz357',
                passwordChanged: mockedAuthTokenPwdChanged()
            },
            profile: {
                firstName: 'mohan',
                lastName: 'ashokan',
                locale: 'en',
                login: validEmail,
                timeZone: zoneTime
            },
            _links: { cancel: { href: hrefUrl, hints: { allow: ['POST'] } } }
        },
        tokens: validationToken
    },
   mockedFailedPwdErrors : [
     {
       error: {
         statusCode: 403,
         message: 'Unable to change password - your current password is not entered correctly. Please try again',
         name: 'FORBIDDEN_ERR',
         remainingAttempts: 4
        }
      },
     {
       error: {
         statusCode: 403,
         message: 'Unable to change password - your current password is not entered correctly. Please try again',
         name: 'FORBIDDEN_ERR',
         remainingAttempts: 0
       }
     },
     {   
       error: {
        statusCode: 403,
        message: 'Unable to change password - your new password cannot be any of the past 12 passwords used with our system. Please try again',
        name: 'FORBIDDEN_ERR',
        remainingAttempts: 4
       }
     },
     {
       error: {
        statusCode: 400,
        message: 'boom'
      }
    }
   ],
   Login_API_ROOT_URL_MOCK: 'http://localhost:9876/',
   mockedAppConfig: [
     {mosaic: 'http://localhost:4202/'},
     {alpha: 'http://localhost:4203/'},
     {transferee: 'http://localhost:4204/'}
   ]
};

export const configNotLocal = {
  local: false,
  oktaClientId: '0oa1fxblvqAFpW88N357',
  oktaUrl: 'https://cartus.oktapreview.com/oauth2/auspye2nuzSGJ0Kje0h7',
  oktaRedirectUri: localUrl,
  movepro360: 'http://localhost:4202/',
  alpha: 'https://mobilifyhrdev01.cartus.com/',
  transferee: 'http://localhost:4204/',
  vendor: 'http://localhost:4205/',
  benefitsbuilder: 'http://localhost:4203/',
  supplier: 'http://localhost:4207/',
  api: {
    host: 'localhost',
    port: '4000',
    protocol: 'http',
    base_url: ''
  }
};

function mockedAuthTokenPwdChanged() {
    return '2019-09-25T09:28:03.000Z';
}

function loadValidCred() {
    return 'Abc12345';
}

function getUserDetails() {
  return {
    'phoneNumber': '7708506725',
    'userId': '5d7031878aea9a061ee8f5b9',
    'userName': 'Balaji',
    'emailAddress': 'Bala@gmail.com',
    'clientId': '5d7031878aea9a061ee8f5qwe',
    'clientName': 'Mindtree Ltd',
    'roleName': 'candidate',
    'product': 'Alpha'
  };
}

function getMockClaim() {
  return {
    'sub': '89d637a6586642b098cd',
    'email': 'bala@gmail.com',
    'ver': 1,
    'iss': 'https://cartus.oktapreview.com/oauth2/fa9e6db31bcb4278be52',
    'aud': 'f162f4df747942cdb2df',
    'iat': 1234567890,
    'exp': 1234567890,
    'jti': 'ID.3670f015D-ce5744-4a2ea3ae3649fd13a1f8f3re4G',
    'amr': ['pwd'],
    'idp': '7e76620be8e44e62b440',
    'nonce': 'e7ddd7405d2f432cab3333b8cc5301cf38569b61628d446fa6082668907f68e5',
    'auth_time': 9876543210,
    'at_hash': 'a3fa2317a62b45b485-DBW'
  }
}

function loadValidUserId() {
  return '5d7031878aea9a061ee8f5b9';
}

function addUserCred() {
  return 'Balas@123';
}

function addUserEmail() {
  return 'Bala@gmail.com@123';
}

function addCartusEmail() {
  return 'bruce.wayne@cartus.com';
}

export const userDetails: User = {
    phoneNumber: '7708506725',
    userId: '5d7031878aea9a061ee8f5b9',
    userName: 'Balaji',
    emailAddress: 'Bala@gmail.com',
    clientId: '5d7031878aea9a061ee8f5qwe',
    clientName: 'Mindtree Ltd',
    roleName: 'candidate',
    product: 'Alpha'
};
export const userDetails1: User = {
    phoneNumber: '7708506725',
    userId: '5d7031878aea9a061ee8f5b9',
    userName: 'Balaji',
    emailAddress: 'Bala@gmail.com',
    clientId: '5d7031878aea9a061ee8f5qwe',
    clientName: 'Mindtree Ltd',
    roleName: 'client-contact',
    product: 'Alpha'
};

export const usersDetails: User[] = [
  {
    phoneNumber: '7708506725',
    userId: '5d7031878aea9a061ee8f5b9',
    userName: 'Balaji',
    emailAddress: 'Bala@gmail.com',
    clientId: '5d7031878aea9a061ee8f5fdh',
    clientName: 'Mindtree Ltd',
    roleName: 'candidate',
    product: 'Alpha'
  },
  {
    phoneNumber: '7708506725',
    userId: 'b25f9847a4074228aede7606',
    userName: 'ckent',
    emailAddress: 'ckent@dailplanet.com',
    clientId: '5d7031878aea9a061ee8f5qwe',
    clientName: 'Man of Steel',
    roleName: 'candidate',
    product: 'MovePro',
  },
  {
    phoneNumber: '7708506725',
    userId: 'f55825a7b9d40839343df25a',
    userName: 'hman',
    emailAddress: 'hman@gmail.com',
    clientId: '5d7031878aea9a061ee8f5qwe',
    clientName: 'He-Man',
    roleName: 'supplier-contact',
    product: 'Alpha'
  },
  {
    phoneNumber: '7708506725',
    userId: '1e16e15016e34214b2a4f40a',
    userName: 'bbunny',
    emailAddress: 'bbunny@gmail.com',
    clientId: '5d7031878aea9a061ee8f5qwe',
    clientName: `Bug's House`,
    roleName: 'client-contact',
    product: 'BenefitsBuilder',
  },
  {
    phoneNumber: '7708506725',
    userId: '24d3546ebd3142e4b68499fd',
    userName: 'lskywalker',
    emailAddress: 'lskywalker@rebelbase.com',
    clientId: '5d7031878aea9a061ee8f5qwe',
    clientName: 'Rebel Alliance',
    roleName: 'client-contact',
    product: 'SupplierPortal',
    identityProviderId: '95ddf6a7b7034eeb9aa96523f'
  }
];

export const addUser: UserRegister = {
    partyId: '5d7031878aea9a061ee8f5b9',
    username: 'Bala@gmail.com',
    password: addUserCred()
};

export const mockedDatatosendOTP: User = {

    userId: '5d7031878aea9a061ee8f5b9',
    phoneNumber: '7708506725',
};

export const mockedDatatovalidateOTP: UserValidation = {

    userId: '5d7031878aea9a061ee8f5b9',
    otp: '123456',
};

export const mockedAddedUserResponses = [
  {
    partyId: '5d921749d6f3df22c5778530',
    status: 'ACTIVE',
    created: '2019-11-13T08:00:33.000Z',
    firstName: 'Balaji',
    lastName: 'Ezhilarasan',
    email: 'Balaji.Ezhilarasan@cartus.com'
  },
  {
    status: 200
  },
  {
    status: 400
  },
  {
    status: 500,
    error: {}
  },
  {
    status: 400,
    error: {
      message: 'Self registration has expired'
    }
  },
  {
    status: 400,
    error: {
      message: 'An object with this field already exists in the current organization'
    }
  }
]

export const API_ROOT_URL_MOCK = 'http://localhost:4000';

export const userIdMock = '5d7031878aea9a061ee8f5b9';
/**checkUser mock response */
export const checkUserResponse = {
    code: 'OTHERS',
    message: 'This user is already registered',
    statusCode: 200
};
/**checkUser mock response */
export const checkCandidateResponse = {
    code: 'CANDIDATE',
    message: 'This user is already registered',
    statusCode: 400
};
/**checkUser mock response */
export const checkClientContactResponse = {
    code: 'CLIENTCONTACT',
    message: 'This user is already registered',
    statusCode: 400
};
