import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogoutComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthenticationService,
    private readonly appConfig: AppConfigService,
    private readonly cookieLogoSvc: ManageCookieLogoService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // Remove Cartus session cookie
      this.cookieLogoSvc.removeCookies(
        ['car-ses-tok',
         'car-ses-time',
         'car-token-claims',
         'car-ses-username',
         'car-token-expiresat',
         'car-token-idtoken',
         'lastAction']);
      // Sign out of Okta and redirect
      this.authService.signOut().then(() => {
        this.navigateToLogin(params); // Redirect to login once all logout processing is done
      }).catch(() => {
        this.navigateToLogin(params); // Not Okta authenticated
      });
    });
  }

  navigateToLogin(params) {
    let extras: any;
    if (params && params.code) {
      extras = {
        queryParams: {
          code: params.code
        }
      };
    }
    this.router.navigate(['login'], extras);
  }
}
