import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { copyright } from 'src/app/core/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  /**property to print copyright string */
  flexProperty = 'column';
  showFullWidth = false;
  readonly copyright = copyright;
  constructor(
    private router: Router
    ) {}

  ngOnInit() {
  }

}
