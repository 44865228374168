import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelfRegistrationSetupService } from 'src/app/core/services/self-registration-setup.service';
import { User } from '../../../core/models/User';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { MatStepper, MatSnackBar } from '@angular/material';
import { PersistenceService, StorageType } from 'angular-persistence';
import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';

/** Class for  SelfRegistration */
@Component({
  selector: 'app-self-registration',
  templateUrl: './self-registration.component.html',
  styleUrls: ['./self-registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelfRegistrationComponent implements OnInit {
  /** Used to inject MatStepper */
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  /** Variable to store user Details */
  userDetails: User;
  /** Variable to store PartyId */
  partyId: string;
  /** Variable to store Step number */
  completedStep: number;
  /** Variable to to current stepping number */
  steppingId: number;
  /** subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Used to hold logo config value */
  logoConfig: any;
  /** string constants to be displayed in self registration page */
  templateString = {
    WELCOME: 'Welcome to Cartus – A leader in global mobility!',
    INFORMATION: `Our primary goal is to provide you with world-class service whether you are one of our corporate client ` +
      `contacts, their employee or our supplier partner. You will benefit from our 60+ years of experience as we help ` +
      `navigate you through all phases of the relocation process.`,
    BANNER_TXT: `Technology That Moves You`
  };

  /**
     * Base constructor
     * @param fb Formbuilder variable
     * @param location location service
     * @param persistenceService PersistenceService
     * @param spinner to get NgxSpinner
     * @param registreationSetupService SelfRegistrationSetupService
     * @param activatedRoute To route to the particular location
     */
  constructor(private readonly fb: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly registrationSetupService: SelfRegistrationSetupService,
    private readonly location: Location,
    private readonly persistenceService: PersistenceService,
    private readonly spinner: NgxSpinnerService,
    private readonly snackBar: MatSnackBar,
    private readonly manageMoveSharedService: ManageUserSharedService,
    private readonly cookieLogoSvc: ManageCookieLogoService) {
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if ((event.url === event.urlAfterRedirects) && (this.persistenceService.get('completedStep', StorageType.SESSION))) {
          const lastCompletedStep = JSON.parse(
            this.persistenceService.get('completedStep', StorageType.SESSION)
          );
          this.completedStep = lastCompletedStep;
        }
      });
  }

  /** To Initialize Component */
  ngOnInit() {
    this.spinner.show();
    this.partyId = this.activatedRoute.snapshot.url[1].path;
    this.getUserDetails(this.partyId);
    let currentStep;
    this.completedStep = this.completedStep
      ? this.completedStep
      : 0;
    this.subscription.add(
      this.activatedRoute.params.subscribe(params => {
        if (!params['id']) {
          this.changeStep(0);
          this.persistenceService.set('currentStep', JSON.stringify(0), { type: StorageType.SESSION });
        } else {
          currentStep = parseInt(this.persistenceService.get('currentStep', StorageType.SESSION), 10);
          this.steppingId = parseInt(params['id'], 10);
          if (currentStep === this.steppingId) {
            this.changeStep(this.steppingId);
          } else {
            this.changeStep(currentStep);
          }
        }
      })
    );
  }

  /**
   * To set the selected index
   * @param event - selected index
   */
  public onStepChange(event: any): void {
    const stepVal = `/registration/${this.partyId}/step/${event.selectedIndex}`;
    this.location.go(stepVal);
  }

  /**
   * to find completed step and change step
   * @param completedStep - step completed
   */
  onNotify(completedStep: number): void {
    this.persistenceService.set('completedStep', JSON.stringify(completedStep),
      { type: StorageType.SESSION });

    if (completedStep === 0) {
      this.changeStep(1);
    } else if (completedStep === 1) {
      this.changeStep(2);
    }
  }

  /**
   * Used to go to given index
   * @param index selected index
   */
  changeStep(index: number): void {
    this.stepper.selectedIndex = index;
    const stepVal = `/registration/${this.partyId}/step/${index}`;
    this.location.go(stepVal);
  }

  /**
   * To get user details
   * @param partyId - partyId
   */
  getUserDetails(partyId: string) {
    this.subscription.add(
      this.registrationSetupService
        .getUser(partyId)
        .subscribe((userDetails: User) => {
          if (userDetails) {
            this.userDetails = userDetails;
            const res = this.cookieLogoSvc.setLogoFromUser(this.userDetails);
            this.logoConfig = { logo: res, type: 'main' }
            this.manageMoveSharedService.updateData(this.userDetails);
            // check for duplicateRegistration
            if (!!userDetails.identityProviderId && userDetails.identityProviderId.length > 0) {
              this.router.navigate(['duplicateRegistration']);
            }
            this.spinner.hide();
          } else {
            this.spinner.hide();
            this.snackBar.open(
              'We are unable to process your request.  Please contact your HR sponsor.',
              undefined,
              { duration: 5000 }
            );
          }
        })
    );
  }

}
