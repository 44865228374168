<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<form fxLayout="row" [formGroup]="regValidationForm" fxFlex> 
        <div class="otp-section panel-bg">
                <div class="otp-header">{{templateString.PHONE_PLACEHOLDER_TEXT}}</div>
                <div class="otp-phone-number">
                    <p class="otp-hidden-char clean">{{phoneNumberHiddenChar}}
                        <span class="phone-last-digits">{{PhoneNumberLastChars}}</span>
                    </p>
                    <p class="otp-text clean"> 
                            <span>{{contentTextPrefix}}</span>
                            <span *ngIf = "isCandidateorTransferee"> {{userDetails.clientName}} </span>
                            <span *ngIf = "!isCandidateorTransferee"> {{clientContactName}} </span>
                            <span>{{contentTextPostfix}}</span>
                    </p>
                </div>
                <div class="btn-align-center" *ngIf="!isOtpSent">
                    <div class="login-contain-btn send-btn">
                        <button type="button" (click)="sendOTP()" id="sendOTP">
                            {{templateString.SEND_CODE_BTN}}
                        </button>
                    </div>
                </div>
                <div class="otp-code-container" *ngIf="isOtpSent">
                    <p class="clean otp-code-text">
                        <span>{{otpTextPrefix}}</span>
                        <span class="link-style">
                            <a (click)="resendOTP()" id="resendOTP">{{templateString.RESEND_CODE}}</a>
                        </span>
                        <span>{{optTextPostfix}}</span>
                    </p>
            
                    <div class="mat-form-input submit-otp">
                        <mat-form-field appearance="fill">
                            <mat-label>Security Code</mat-label>
                            <input matInput placeholder="SecurityCode" formControlName="otp" (ngModelChange)="checkOTP()">
                            <mat-error>{{ getErrorMessage('OTP') }}</mat-error>
                            <mat-error *ngIf="regValidationForm.controls['otp'].hasError('invalidOTP')">
                                {{errorString.INVALID_OTP}}
                             </mat-error>
                             <mat-error *ngIf="regValidationForm.controls['otp'].hasError('exceededAttempts')">
                                {{errorString.OTP_ATTEMPTS_EXCEEDED}}
                             </mat-error>
                        </mat-form-field>
                        <div class="login-contain-btn btn-align-center">
                            <button type="submit" [disabled]="!regValidationForm.valid && !isContinueBtnEnable" (click)="validateOTP()" id="submitOTP">
                                {{templateString.CONTINUE_BTN}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    </form>