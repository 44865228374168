import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PersistenceModule} from 'angular-persistence';
import {NgIdleModule} from '@ng-idle/core';


import {
  MatToolbarModule,
  MatFormFieldModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatTableModule,
  MatDividerModule,
  MatSnackBarModule,
  MatStepperModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './public/components/login/login.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {LogoutComponent} from './public/components/logout/logout.component';
import {ExternRouteComponent} from './public/components/extern-route/extern-route.component';
import {CookieService} from 'ngx-cookie-service';
import {SelfRegistrationComponent} from './public/components/selfRegistration/self-registration.component';
import {SelfRegistrationValidationComponent} from './public/components/selfRegistration/selfRegistration-validateUserComponent/self-registration-validate-user.component';
import {SelfRegistrationCreateUserComponent} from './public/components/selfRegistration/selfRegistration-CreateUserComponent/self-registration.createUser.component';
import {SelfRegistrationSuccessComponent} from './public/components/selfRegistration/selfRegistration-SuccessComponent/self-registration-success.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LeftPanelComponent} from './public/components/left-panel/left-panel.component';
import {UpdatePasswordComponent} from './public/components/update-password/update-password.component';
import {UpdatePasswordSucessPageComponent} from './public/components/update-password-sucess-page/update-password-sucess-page.component';
import {PrivacyNoticeComponent} from './public/components/privacy-notice/privacy-notice.component';
import {DuplicateRegistrationComponent} from './public/components/duplicateRegistration/duplicate-registration.component';
import {FooterComponent} from './public/components/footer/footer.component';
import {ContactUsComponent} from './public/components/contact-us/contact-us.component';
import {PrivacyStatementComponent} from './public/components/privacy-statement/privacy-statement.component';
import {TermsOfUseComponent} from './public/components/terms-of-use/terms-of-use.component';
import {HeaderComponent} from './public/components/header/header.component';
import {ToastrModule} from 'ngx-toastr';
import {SessionTimeoutComponent} from './public/components/session-timeout/session-timeout.component';
import {CustomSnackbarComponent} from './public/components/custom-snackbar/custom-snackbar.component';
import { LogoComponent } from './public/components/logo/logo.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    ExternRouteComponent,
    SelfRegistrationComponent,
    SelfRegistrationValidationComponent,
    SelfRegistrationCreateUserComponent,
    SelfRegistrationSuccessComponent,
    LeftPanelComponent,
    UpdatePasswordComponent,
    UpdatePasswordSucessPageComponent,
    PrivacyNoticeComponent,
    DuplicateRegistrationComponent,
    SessionTimeoutComponent,
    FooterComponent,
    HeaderComponent,
    ContactUsComponent,
    PrivacyStatementComponent,
    TermsOfUseComponent,
    CustomSnackbarComponent,
    LogoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatTableModule,
    MatDividerModule,
    MatSnackBarModule,
    MatStepperModule,
    PersistenceModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgIdleModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      maxOpened: 3,
      preventDuplicates: true,
    }),
    AppRoutingModule // I must be last!! https://angular.io/guide/router#module-import-order-matters
  ],
  entryComponents: [
    SessionTimeoutComponent,
    CustomSnackbarComponent
  ],
  providers: [
    Title,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
