import { Injectable } from '@angular/core'
import { AppConfigService } from 'src/app/core/services/app-config.service'
import { CookieService } from 'ngx-cookie-service'
import { User } from 'src/app/core/models/User'

@Injectable({
  providedIn: 'root'
})

// This service handles the setting of logos and cookie settings and removals
export class ManageCookieLogoService {
  /*Available Sites*/
  authorizedLocations = ['movepro360', 'alpha', 'transferee', 'supplier', 'benefitsbuilder']
  /* Okta session timeout */
  idleTimeoutMinutes = 55
  /** domain variable */
  domain: string = '.cartus.com'

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService
  ) { }

   /**
   * To set the cookies in the browser
   * @param res - oktaResponse
   * @param cookieNames - array of cookie names
   */
  setCookies(res: any, cookieNames: string[]): void {
    const sessionTime: string = new Date().getTime() + (this.idleTimeoutMinutes * 60000).toString()
    cookieNames.forEach(cookieName => {
      switch(cookieName) {
        case 'car-ses-tok': {
          if (this.appConfig.getConfig('local')) {
            this.cookieService.set(
              cookieName,
              res.tokens.accessToken.accessToken,
              undefined,
              undefined,
              undefined,
              undefined,
              'Lax') // Developer
          } else {
            this.cookieService.set(cookieName,
              res.tokens.accessToken.accessToken,
              null, // We are relying on Okta session expiration
              '/',
              this.domain,
              true
            )
          }
          break
        }
        case 'car-ses-time': {
          if (this.appConfig.getConfig('local')) {
            this.cookieService.set(
              cookieName,
              sessionTime,
              undefined,
              undefined,
              undefined,
              undefined,
              'Lax') // Developer
          } else {
            this.cookieService.set(cookieName,
              sessionTime,
              null,
              '/',
              this.domain,
              true
            )
          }
          break
        }
        case 'car-token-claims': {
          // check if this needs to be set for SSO.
          if (this.appConfig.getConfig('local')) {
            this.cookieService.set(
              cookieName,
              JSON.stringify(res.tokens.idToken.claims),
              undefined,
              undefined,
              undefined,
              undefined,
              'Lax') // Developer
          } else {
            this.cookieService.set(cookieName,
            res.tokens.idToken.claims ? JSON.stringify(res.tokens.idToken.claims) : undefined,
            null,
            '/',
            this.domain,
            true)
          }
          break
        }
        case 'car-ses-username': {
          if (!this.appConfig.getConfig('local')) {
            this.cookieService.set(
              cookieName,
              res.tokens.idToken.claims.email,
              null, // We are relying on Okta session expiration
              '/',
              this.domain,
              true
            )
          }
          break
        }
        case 'car-token-expiresat': {
          if (!this.appConfig.getConfig('local')) {
            this.cookieService.set(
              cookieName,
              res.tokens.idToken.expiresAt,
              null,
              '/',
              this.domain,
              true
            )
          }
          break
        }
        case 'car-token-idtoken': {
          if (!this.appConfig.getConfig('local')) {
            this.cookieService.set(
              cookieName,
              res.tokens.idToken.idToken,
              null,
              '/',
              this.domain,
              true
            )
          }
          break
        }
        default: {
          break
        }
      }
    })
  }

   /**
   * To remove the cookies in the browser
   * @param cookieNames - array of cookie names
   */
  removeCookies(cookieNames: string[]): void {
    // work-around for deleting cookies since delete is broken in ngx-cookie-service
    // for in last version compatible with Angular 8
    const oldDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT')
    cookieNames.forEach(cookieName => {
      switch (cookieName) {
        case 'car-ses-tok':
        case 'car-ses-time':
        case 'car-token-claims':
        case 'cas-ses-logo':
        {
          if (this.appConfig.getConfig('local')) {
            this.cookieService.set(
              cookieName,
              '',
              oldDate,
              '/',
              'localhost',
              false,
              'Lax'
              )
          } else {
            this.cookieService.set(
              cookieName,
              '',
              oldDate,
              '/',
              this.domain,
              false,
              'Lax'
              )
          }
          break
        }
        case 'car-ses-username':
        case 'car-token-expiresat':
        case 'car-token-idtoken':
        case 'lastAction':
        {
          if (!this.appConfig.getConfig('local')) {
            this.cookieService.set(
              cookieName,
              '',
              oldDate,
              '/',
              this.domain,
              false,
              'Lax'
              )
          }
          break
        }
        default: {
          break
        }
      }
    })
  }

   /**
   * To set the logo for the template based on the referredU
   * @param referrerdURL - source app url
   * @return A response containing the Logo model (object)
   */
  setLogo(referredURL: string): string {

    // To hold the logo type
    let logo: string

    // Application URLs
    const moveProURL = this.appConfig.getConfig(this.authorizedLocations[0]).toString().split('|')
    const alphaURL = this.appConfig.getConfig(this.authorizedLocations[1]).toString().split('|')
    const transfereeURL = this.appConfig.getConfig(this.authorizedLocations[2]).toString().split('|')
    const vendorURL = this.appConfig.getConfig(this.authorizedLocations[3]).toString().split('|')
    const bbURL = this.appConfig.getConfig(this.authorizedLocations[4]).toString().split('|')

    // /* For MovePro360 Logo */
    if (referredURL === moveProURL[0].replace(/(\/#|\/|#)$/, '') ||
         (moveProURL.length > 1 && referredURL === moveProURL[1].replace(/(\/#|\/|#)$/, ''))) {
           return 'movePro'
        }
    // /* For BB Logo */
    if (!logo && (referredURL === bbURL[0].replace(/(\/#|\/|#)$/, '') ||
         (bbURL.length > 1 && referredURL === bbURL[1].replace(/(\/#|\/|#)$/, '')))) {
           return 'benefitsBuilder'
        }
    // /* For Alpha Logo */
    if (!logo && (referredURL === alphaURL[0].replace(/(\/#|\/|#)$/, '')) ||
         (alphaURL.length > 1 && referredURL === alphaURL[1].replace(/(\/#|\/|#)$/, '')) ||
         (referredURL === transfereeURL[0].replace(/(\/#|\/|#)$/, '')) ||
         (transfereeURL.length > 1 && referredURL === transfereeURL[1].replace(/(\/#|\/|#)$/, ''))) {
           return 'alpha'
         }

    // /* For Supplier Logo */
    if (!logo && (referredURL === vendorURL[0].replace(/(\/#|\/|#)$/, '')) ||
         (vendorURL.length > 1 && referredURL === vendorURL[1].replace(/(\/#|\/|#)$/, ''))) {
           return 'supplier'
        }

  }

   /**
   * To set the logo for the template based on userDetails
   * @param userDetails - User Model object
   * @return Logo string
   *    */
  setLogoFromUser(userDetails: User): string {
    if (userDetails.product === 'Alpha') {
      return 'alpha'
    } else if (userDetails.product === 'MovePro') {
      return "movePro"
    } else if (userDetails.product === 'BenefitsBuilder') {
      return 'benefitsBuilder'
    }
  }

}
