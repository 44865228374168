import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AppConfigService } from '../../../core/services/app-config.service';

@Component({
  selector: 'app-update-password-sucess-page',
  templateUrl: './update-password-sucess-page.component.html',
  styleUrls: ['./update-password-sucess-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdatePasswordSucessPageComponent implements OnInit {

  templateString = {
    BANNER_TXT: `Technology That Moves You`,
    UPDATE_STATUS: 'Congratulations!',
    UPDATE_INFO: 'Your password has been changed sucessfully, please choose an application',
  };

  /** Activates if the resolution is of a mobile device */
  responsiveView: boolean;

  /** Variable to store the product name*/
  alphaProductName: string;
  movepro360ProductName: string;
  bbProductName: string;

  // Detect window size
  @HostListener('window:resize')
  onresize() {
    this.getWindowSize();
  }
  constructor(
    private readonly ngxSpinner: NgxSpinnerService,
    private readonly router: Router,
    private readonly appConfig: AppConfigService
  ) { }

  ngOnInit() {
    this.alphaProductName = this.appConfig.getConfig('Alpha_ProductName').toString();
    this.movepro360ProductName = this.appConfig.getConfig('MovePro360_ProductName').toString();
    this.bbProductName = this.appConfig.getConfig('BB_ProductName').toString();
    this.getWindowSize();
    this.ngxSpinner.hide();
  }

  redirectToSelectedProduct(destPage) {
    this.ngxSpinner.show();
    const urls = this.appConfig.getConfig(destPage).toString().split('|');
    this.ngxSpinner.hide();
    this.router.navigate(['/externalRedirect', { externalUrl: urls[0] }], {
      skipLocationChange: true,
    });
  }

  getWindowSize() {
    const windowWidth = window.innerWidth;

    if (windowWidth <= 959) {
      this.responsiveView = true;
    } else {
      this.responsiveView = false;
    }
  }
}
