<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="loginContainer FooterAlign" fxFlex="100%" fxLayout fxLayout.xs="column" fxLayout.sm="column">
  <app-left-panel fxFlex="50%" fxHide.xs fxHide.sm></app-left-panel>
  <div fxFlex="50%" class="loginContentBlock">
    <mat-card class="loginDetailsBlock">
      <mat-card-content>
        <app-logo [logoConfig]="logoConfig"></app-logo>
        <div class="intro-message body-copy" ngClass.sm="med-margin">
          <div *ngIf="logoConfig && logoConfig.logo === 'movePro'">{{templateString.MOVEPRO_TXT}}</div>
        </div>
        <div id="widget" [ngClass]="{ 'inactive-user': inactiveUser || !logoConfig }"></div>
        <div class="message" *ngIf="message && !inactiveUser">{{message}}</div><br />
        <div class="errorBlock" *ngIf="loginErrorMsg">
          <div class="small-text">{{loginErrorMsg}}</div>
        </div>
        <div *ngIf="inactiveUser">
          <div class="heading">{{message}}</div>
          <div class="sub-heading body-copy">
            If you need assistance with your account, please contact your Cartus Representative or the <a
              href="mailto:helpdesk-cartus@cartus.com">Cartus Help Desk</a>
          </div>
          <div class="login-contain-btn btn-align-center">
            <button mat-flat-button class="mat-button" [routerLink]="['/login']"
              (click)=" message = ''; inactiveUser = false" type='button'>Back</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <app-footer></app-footer>
  </div>
</div>
