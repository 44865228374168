import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { User } from '../../../core/models/User';
import { TextEncoder } from 'text-encoding'; // Added for Edge browser compatiblity
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { Subscription } from 'rxjs';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { Title } from '@angular/platform-browser';

/** Base Login component */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  /** Subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Subscription for preference */
  prefSub: Subscription;
  /** Password display style, clear text or masked */
  hide: boolean;
  /** Error message when authentication or authorization fails */
  loginErrorMsg: string;
  /*Available Sites*/
  authorizedLocations = ['movepro360', 'alpha', 'transferee', 'supplier', 'benefitsbuilder'];
  /*To check redirected from authorised site or not*/
  authorisedSite = false;
  /*For the redirected URL */
  referredURL: string = '';
  /* For the Self registraion Login redirecting */
  selfRegLoginURL: string;
  /* Request Email Button Status*/
  requestEmailStatus = false;
  /* Okta session timeout */
  idleTimeoutMinutes = 55;
  /* Displays message to user (i.e. idle or session timeout) */
  message: string;
  /*Inactive user flag*/
  inactiveUser = false;
  /*Edge Chromium flag*/
  isEdgeChromium = false;
  /* Okta widget */
  signInWidget: OktaSignIn;
  /* Okta widget config */
  signInWidgetConfig = {};
  /**
 * Initializes the value
 * @param router Instance Router
 */
  /**Base Constructor for Login Component
   * @param authSvc Authentication Service to authenticate the user details
   */
  /** Login Form.  Contains email and password fields, which are both required. */
  loginForm = this.fb.group({
    email: new FormControl('',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')
      ])),
    password: ['', [Validators.required]]
  });
  templateString = {
    BNNER_TXT: `Technology That Moves You`,
    MOVEPRO_TXT: `Welcome to MovePro360 ─ your centralized mobility hub and single source of truth ` +
    `for all your relocation and global talent mobility goals. Optimize move outcomes, anticipate ` +
    `needs, access predictive insights, and much more.`
  };
  /** Used to hold Candidate Move Data */
  userDetails: User;
  /** Used to hold logo config value */
  logoConfig: any;

  constructor(
    private readonly authSvc: AuthenticationService,
    private readonly spinner: NgxSpinnerService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly moveSharedService: ManageUserSharedService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly cookieLogoSvc: ManageCookieLogoService,
    ) {
    /** Added for Edge Browser compatiblity */
    if (!window['TextEncoder']) {
      window['TextEncoder'] = TextEncoder;
    }
  }

  /** Login Component's Init Function */
  ngOnInit() {
    this.titleService.setTitle('Login');
    this.spinner.show();
    this.isEdgeChromium = this.detectEdgeChromium();
    this.loginErrorMsg = '';
    // Initialize Okta widget
    this.signInWidgetConfig = {
      language: 'en',
      i18n: {
        'en': {
          'primaryauth.title': 'Enter your credentials to log in',
          'primaryauth.submit': 'Log In'
        }
      },
      features: {
        rememberMe: false,
        selfServiceUnlock: true,
        multiOptionalFactorEnroll: true,
        autoPush: true
      },
      baseUrl: this.appConfig.getConfig('oktaUrl').toString().split('/oauth2')[0], // Derive from issuer
      clientId: this.appConfig.getConfig('oktaClientId'),
      redirectUri: this.appConfig.getConfig('oktaRedirectUri'),
      authParams: {
        issuer: this.appConfig.getConfig('oktaUrl'),
        responseType: ['id_token', 'token'],
        scopes: ['openid', 'email', 'profile'],
      },
      helpLinks: {
        help: '/#/contactUs'
      },
    };
    this.signInWidget = new OktaSignIn(this.signInWidgetConfig);
    this.signInWidget.renderEl(
      { el: '#widget' },
      (res: any) => {
        this.onLoginResponse(res);
      },
      (err: any) => {
        console.error(err);
      }
    );
    // Handle message codes
    this.route.queryParams.subscribe(params => {
      if (params.code) {
        switch (params.code) { // These codes are being kept in alignment with CartusOnline for consistency
          case '4': {
            this.message = 'You have been logged out due to inactivity.';
            break;
          }
          case '5': {
            this.message = 'Your account is no longer active.';
            this.inactiveUser = true;
            break;
          }
          case '14': {
            this.message = 'Your session has expired.';
            break;
          }
          default: {
            this.message = null;
          }
        }
      }
    });
    // Redirect to authorized destination
    this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(val => {
      if (val) {
        this.referredURL = val.replace(/(\/#|\/|#)$/, '');
        const res = this.cookieLogoSvc.setLogo(this.referredURL)
        if (res) {
          this.logoConfig = { logo: res, type: 'main' }
        }

      }
    });

    this.moveSharedService.loginUserDetails.subscribe(loginUserDetails => {
      this.userDetails = loginUserDetails;
      if (!!this.userDetails && !!this.userDetails.userId && this.userDetails.userId.length > 0) {
        switch (this.userDetails.product) {
          case 'Alpha':
            this.logoConfig = { logo: 'alpha', type: 'main' }
            break
          case 'MovePro':
            this.logoConfig = { logo: 'movePro', type: 'main' }
            break
          case 'BenefitsBuilder':
            this.logoConfig = { logo: 'benefitsBuilder', type: 'main' }
            break
          case 'SupplierPortal':
            this.logoConfig = { logo: 'supplier', type: 'login' }
            break
          default:
            break
        }
      }
    });

    // Check to display wrong URL
    if (!this.referredURL && !this.userDetails) {
      this.loginErrorMsg = 'Please launch the correct URL';
    }

    // SSO check
    // No SSO in local mode
    if (!this.appConfig.getConfig('local')) {
      this.authSvc.trySso().then((tokenOrTokens: any) => {
        if (tokenOrTokens) {
          // SSO authenticated
          this.cookieLogoSvc.setCookies(tokenOrTokens,
          ['car-ses-tok',
          'car-ses-time',
          'car-token-claims',
          'car-ses-username',
          'car-token-expiresat',
          'car-token-idtoken']);
          this.subscription.add(
          this.authSvc
          .resetPasswordAttempts({
            "Authorization": tokenOrTokens.tokens.accessToken.accessToken
          }).subscribe(() => {
            this.userPreferencesService.getPreference('referrer', false).subscribe(referrer => {
              if (referrer) {
                this.router.navigate(['/externalRedirect', { externalUrl: referrer }], {
                  skipLocationChange: true,
                });
              } else {
                this.spinner.hide(); // No referrer
              }
            });
          }));
        } else {
          this.spinner.hide(); // Not SSO authenticated
        }
      });
    } else {
      this.spinner.hide(); // No SSO Check
    }

  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.signInWidget) {
      this.signInWidget.remove();
    }
    if (this.prefSub) {
      this.prefSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /** detect Edge Chromium for SP-341: Buttons and text indistingushable in High Contrast Mode */
  detectEdgeChromium(): boolean {
    const agent = window.navigator.userAgent.toLowerCase();
    return agent.indexOf('edg') > -1 ?  true : false;
  }

  onLoginResponse(res: any): void {
    if (res.status === 'FORGOT_PASSWORD_EMAIL_SENT') {
      return;
    }
    if (res.status === 'UNLOCK_ACCOUNT_EMAIL_SENT') {
      return;
    }
    if (res.status === 'PASSWORD_EXPIRED') { // Is this still needed?
      if (res.tokens.idToken.claims.email.toLowerCase().includes('cartus.com')) {
        this.router.navigate(['/updatePassword']);
      }
    }
    if (res.status === 'LOCKED_OUT') { // Is this still needed?
      if (res.tokens.idToken.claims.email.toLowerCase().includes('cartus.com')) {
        this.requestEmailStatus = true;
      }
    }
    if (res.status === 'SUCCESS') {
      // SSO Code for later - Do not remove
      // // OIDC
      // if (res.type === 'SESSION_STEP_UP') {
      //   console.log(res.user);
      //   console.log('Target resource url: ' + res.stepUp.url);
      // res.stepUp.finish();
      //   return;
      // } else {
      //   console.log(res.user);
      //   res.session.setCookieAndRedirect('https://acme.com/app');
      //   return;
      // }
      // // Not OIDC
      // console.log(res.claims);
      // this.signInWidget.tokenManager.add('my_id_token', res);
      // this.signInWidget.tokenManager.add('my_id_token', res[0]);
      // this.signInWidget.tokenManager.add('my_access_token', res[1]);
      // return;
      if (res && res.tokens && res.tokens.idToken && res.tokens.idToken.claims && res.tokens.idToken.claims.sub) {
        this.subscription.add(
          this.authSvc
            .resetPasswordAttempts({
              "Authorization": res.tokens.accessToken.accessToken
            })
            .subscribe((() => {
              this.cookieLogoSvc.setCookies(res,
              ['car-ses-tok',
               'car-ses-time',
               'car-token-claims',
               'car-ses-username',
               'car-token-expiresat',
               'car-token-idtoken']);
              // Store the tokens in the token manager so it can signout and revoke
              this.authSvc.authClient.tokenManager.setTokens(
                {accessToken: res.tokens.idToken,
                  idToken: res.tokens.accessToken}
              );
              for (const i of this.authorizedLocations) {
                const urls = this.appConfig.getConfig(i).toString().split('|');
                if (this.referredURL === urls[0].replace(/(\/#|\/|#)$/, '') || (urls.length > 1 && this.referredURL === urls[1].replace(/(\/#|\/|#)$/, ''))) {
                  this.spinner.hide();
                  this.router.navigate(['/externalRedirect', { externalUrl: urls[0] }], {
                    skipLocationChange: true,
                  });
                  this.authorisedSite = true;
                }
              }
              if (!this.authorisedSite) {
                if (this.userDetails) {
                  let urls = [];
                  if (this.userDetails.product === 'Alpha') {
                    if (this.userDetails.roleName === 'candidate') {
                      urls = this.appConfig.getConfig(this.authorizedLocations[2]).toString().split('|');
                    } else if (this.userDetails.roleName === 'supplier-contact') {
                      urls = this.appConfig.getConfig(this.authorizedLocations[3]).toString().split('|');
                    } else {
                      urls = this.appConfig.getConfig(this.authorizedLocations[1]).toString().split('|');
                    }
                  } else if (this.userDetails.product === 'MovePro') {
                    urls = this.appConfig.getConfig(this.authorizedLocations[0]).toString().split('|');
                  } else if (this.userDetails.product === 'BenefitsBuilder') {
                    urls = this.appConfig.getConfig(this.authorizedLocations[4]).toString().split('|');
                  }
                  this.selfRegLoginURL = urls[0];
                  this.spinner.hide();
                  this.router.navigate(['/externalRedirect', { externalUrl: this.selfRegLoginURL }], {
                    skipLocationChange: true,
                  });
                }
              }
          })
        ))
      }
    }
    this.spinner.hide();
  }

}
