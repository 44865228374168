import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelfRegistrationSetupService } from 'src/app/core/services/self-registration-setup.service';
import { User } from '../../../../core/models/User';
import { PersistenceService, StorageType } from 'angular-persistence';
import { Subscription } from 'rxjs';
import { UserRegister } from '../../../../core/models/UserRegister';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';

/** Class for  SelfRegistrationCreateUser */
@Component({
  selector: 'app-self-registration-create-user',
  templateUrl: './self-registration-createUser.component.html',
  styleUrls: ['./self-registration-createUser.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelfRegistrationCreateUserComponent implements OnInit {
  /** variable for isCandidateOrTransferee */
  isCandidateorTransferee = true;
  /** variable for formgroup */
  selfRegistrationSetupForm: FormGroup;
  /** variable to store user details */
  addUser: UserRegister = {} as UserRegister;
  /** Input parameter userDetails received from parent component */
  @Input() userDetails: User;
  /** Input parameter step number received from parent component */
  @Input() step: number;
  /** Output parameter step number notified to parent component by emiting */
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  /** Subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Stores the strings used in the template */
  templateString = {
    FORM_TITLE: `Creating an account is quick and easy!`,
    PASSWORD_INSTRUCTION_TITLE: `Passwords must be at least 10 characters, and must contain the following details:`,
    PRIVACY_NOTICE_TXT: `By clicking the button below you are agreeing to our`,
    PRIVACY_NOTICE: `Privacy Notice`,
    EMAIL: 'Email',
    PASS: 'Password',
    CONFIRM_PASS: 'Confirm Password',
    CREATE_ACC_BTN: 'Create Account',
    expiredRegistration: 'This registration link has expired.  Please contact your Cartus Consultant for a new registration link.',
    duplicateEmail: 'This email cannot be used to create an account.  Please use a different email or contact Cartus.'
  };
  /** Password instruction display string */
  passwordInstructions = ['At least 1 number', 'At least 1 capital letter', 'At least 1 lower case', 'At least 1 special character'];
  /** Activates if the resolution is of a mobile device */
  responsiveView: boolean;
  // Detect window size
  @HostListener('window:resize')
  onresize() {
    this.getWindowSize();
  }

  /**
 * Base constructor
 * @param registrationSetupService SelfRegistrationSetupService
 * @param persistenceService PersistenceService
 * @param spinner to get NgxSpinner
 */
  constructor(private readonly fb: FormBuilder,
    private readonly registrationSetupService: SelfRegistrationSetupService,
    private readonly persistenceService: PersistenceService,
    private readonly ngxSpinner: NgxSpinnerService,
    private readonly snackBar: MatSnackBar,
    private readonly logSvc: RemoteLoggingService) { }

  /** To Initialize Component */
  ngOnInit() {
    this.getWindowSize();
    this.createControl();
  }

  /** To Create form Controls */
  createControl() {
    this.selfRegistrationSetupForm = this.fb.group({
      email: new FormControl(this.userDetails.emailAddress,
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-]*\\.[a-zA-Z]{2,3}$')
        ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^((?=.{10,})((?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9\s])(?=.*[0-9]))).*$'),
        // Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*)(><_-{}]).{10,}$')
      ])),
      confirmpassword: ['', Validators.required]
    });
    if (this.userDetails.roleName !== 'candidate' && this.userDetails.roleName !== 'transferee') {
      this.selfRegistrationSetupForm.controls['email'].disable();
      this.isCandidateorTransferee = false;
    }
    this.selfRegistrationSetupForm.controls['email'].markAsTouched();
  }

  /** Store the current step in session */
  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit(): void {
    this.persistenceService.set('currentStep', JSON.stringify(this.step),
      { type: StorageType.SESSION });
  }

  /** To check whether both passwords match */
  checkPassword() {
    this.selfRegistrationSetupForm.get('password').value === this.selfRegistrationSetupForm.get('confirmpassword').value ?
      this.selfRegistrationSetupForm.controls['confirmpassword'].setErrors(null) :
      this.selfRegistrationSetupForm.controls['confirmpassword'].setErrors({
        notSame: true
      });
  }
  /** checks if password contains parts of username */
  checkForUsername() {
    const username = this.selfRegistrationSetupForm.get('email').value.split(/[^\w\s]|_/g);
    const password = this.selfRegistrationSetupForm.get('password').value.toLowerCase();
    for (const name of username) {
      if (name.length >= 4 && password.includes(name.toLowerCase())) {
        this.selfRegistrationSetupForm.controls['password'].setErrors({
          containsUsername: true
        });
        break;
      } else if (!this.selfRegistrationSetupForm.get('password').hasError('pattern') &&
        !this.selfRegistrationSetupForm.get('password').hasError('required')) {
        this.selfRegistrationSetupForm.controls['password'].setErrors(null);
      }
    }
  }

  /** To create user */
  createUser() {
    this.ngxSpinner.show();
    this.addUser.username = this.selfRegistrationSetupForm.controls['email'].value;
    this.addUser.password = this.selfRegistrationSetupForm.controls['password'].value;
    this.addUser.partyId = this.userDetails.userId;
    this.addUser.orderRequestId = this.userDetails.orderRequestId;
    this.addUser.product = this.userDetails.product;
    this.addUser.roleName = this.userDetails.roleName;
    this.subscription.add(
      this.registrationSetupService
        .registerUser(this.addUser)
        .subscribe(response => {
          if (!!response) {
            let showGeneralError: boolean = false
            if (response.status && response.status === 400) { // 400 error
              if (response.error && response.error.message && response.error.message === 'Self registration has expired') {
                this.ngxSpinner.hide();
                this.snackBar.open(this.templateString.expiredRegistration, undefined, { duration: 5000 });
              } else if (response.error && response.error.message && response.error.message.includes('An object with this field already exists in the current organization')) {
                this.ngxSpinner.hide();
                this.snackBar.open(this.templateString.duplicateEmail, undefined, { duration: 5000 });
              } else {
                showGeneralError = true
              }
            } else if (response.status && response.status !== 400 && response.error) { // error other than 400
              showGeneralError = true       
            } else { // no error
              this.ngxSpinner.hide();
              let currentStep;
              currentStep = parseInt(this.persistenceService.get('currentStep', StorageType.SESSION), 10);
              this.notify.emit(currentStep);
            }

            if (showGeneralError) {
              this.snackBar.open(
                'We are unable to process your request at this time. Please try again later.',
                undefined,
                { duration: 5000 }
              );
              this.logSvc.logError(response);
            }
          } 
        }
      )
    );
  }

  /**
   * To set error message to fields
   * @param fieldName - Field Name
   */
  getErrorMessage(fieldName): string | undefined {
    if (fieldName === 'EMAIL') {
      return this.selfRegistrationSetupForm.get('email').hasError('required')
        ? 'You must enter a User ID'
        : this.selfRegistrationSetupForm.get('email').hasError('pattern')
          ? 'User ID must be a valid email address'
          : '';
    }
    if (fieldName === 'PASSWORD') {
      return this.selfRegistrationSetupForm.get('password').hasError('required')
        ? 'You must enter a password'
        : this.selfRegistrationSetupForm.get('password').hasError('pattern')
          ? 'You must match password complexity rules'
          : this.selfRegistrationSetupForm.get('password').hasError('containsUsername')
            ? 'Password cannot contain part of email'
            : '';
    }
    if (fieldName === 'CONFIRMPASSWORD') {
      return this.selfRegistrationSetupForm.get('confirmpassword').hasError('required')
        ? 'You must enter a password'
        : '';
    }
  }

  /** To get window size and responsiveview */
  getWindowSize() {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 959) {
      this.responsiveView = true;
    } else {
      this.responsiveView = false;
    }
  }
}
