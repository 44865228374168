/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "@angular/flex-layout/flex";
import * as i12 from "../logo/logo.component.ngfactory";
import * as i13 from "../logo/logo.component";
import * as i14 from "./header.component";
import * as i15 from "../../../core/services/user-preferences.service";
import * as i16 from "../../../core/services/app-config.service";
import * as i17 from "@angular/router";
import * as i18 from "ngx-cookie-service";
import * as i19 from "../../../core/services/manage-cookie-logo.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "headerlink"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "headerlink-movepro": 0 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "headerlink-movepro": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 5, "button", [["class", "text-color"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.redirect ? _co.logout() : _co.clearCookies()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Log out "])), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["exit_to_app"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "headerlink"; var currVal_1 = _ck(_v, 3, 0, (_co.logoConfig.logo === "movePro")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, (_co.logoConfig.logo === "movePro")); var currVal_3 = "headerlink"; _ck(_v, 4, 0, currVal_2, currVal_3); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_4 = (i1.ɵnov(_v, 7).disabled || null); var currVal_5 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵnov(_v, 10).inline; var currVal_7 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_6, currVal_7); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "headerWrapper"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i11.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i11.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-logo", [], null, null, null, i12.View_LogoComponent_0, i12.RenderType_LogoComponent)), i1.ɵdid(3, 114688, null, 0, i13.LogoComponent, [], { logoConfig: [0, "logoConfig"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.logoConfig; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.showLogoutButton; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i14.HeaderComponent, [i15.UserPreferencesService, i15.UserPreferencesService, i16.AppConfigService, i17.Router, i18.CookieService, i19.ManageCookieLogoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i14.HeaderComponent, View_HeaderComponent_Host_0, { redirect: "redirect", logoConfig: "logoConfig" }, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
